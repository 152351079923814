import { createGlobalStyle } from "styled-components";

/**
 * A global styles
 */

const Global = createGlobalStyle`
html,
body {
  padding: 0;
  margin: 0;
  font-family: "Sora", sans-serif;
  font-size: 18px;
  font-weight: 400;
  min-height: 100vh;
}

:root{
  --black: #060714;
  --black-90: #262840;
  --grayWhite: #7780A1;
  --purple: #2A27C9;
  --purple-light: #514CFF;
  --purple-dark: #2A27C9;
  --purple-light-15: #514CFF;
  --accent-green: #76DECC;
  --white: #fff;
  --beads: #BBC0D0;
  --beads-box: #F2F3F6;
}

//Dark variables
[data-theme="dark"] {
  --black: #fff;
  --white: #060714;
  --purple-dark: #514CFF;
  --beads: #7780A1;
  --beads-box: #1B1C28;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

p {
  white-space: pre-line;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
} 

`;

export default Global;
