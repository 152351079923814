
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import { useEffect } from "react";
import Router from "next/router";
import ProgressBar from "@badrap/bar-of-progress";
import Global from "styles/Global.styled";
import type { AppProps } from "next/app";
import { useAtom } from "jotai";
import { darkModeAtom } from "utils/ThemeAtom";
const progress = new ProgressBar({
    size: 4,
    color: "#d12828",
    className: "bar-of-progress",
    delay: 100,
});
//page loader
Router.events.on("routeChangeStart", progress.start);
Router.events.on("routeChangeComplete", progress.finish);
Router.events.on("routeChangeError", progress.finish);
function MyApp({ Component, pageProps }: AppProps) {
    const [darkMode] = useAtom(darkModeAtom);
    //Checking the theme
    useEffect(() => {
        document.documentElement.setAttribute("data-theme", darkMode ? "dark" : "light");
        return () => { };
    }, [darkMode]);
    return (<>
      <Global />
      <Component {...pageProps}/>
    </>);
}
const __Next_Translate__Page__191ee86199b__ = MyApp;

    export default __appWithI18n(__Next_Translate__Page__191ee86199b__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: true,
      loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
    });
  